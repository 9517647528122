* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

body {
    background-color: #ede7e2;
}

.width-100 {
    width: 100% !important;
}

.width-auto {
    width: auto !important;
}

.text-danger {
    color: #ff0000 !important;
    text-align: left;
    padding-top: 5px;
}

.required-title {
    text-align: center;
    margin-bottom: 20px;
}

.required-title span {
    color: #ff0000 !important;
    font-size: 14px;
}

.ascetic-text {
    color: #ff0000 !important;
}

.copyright {
    /* display: none; */
}

.project-title {
    text-decoration: underline;
    color: #008CBA;
}

.heart {
    display: none;
}

#main-wrapper:after,
#main-wrapper:before {
    display: none;
}

.dlab-side-menu {
    display: none;
}

.header {
    padding-right: 0px;
}

.nav-header {
    border-top-right-radius: 0px !important;
    background-color: #332d28;
    padding: 0px 20px;
}

.nav-header a {
    border-bottom: 1px solid gray;
}

.dlabnav {
    background-color: #332d28 !important;
    border-bottom-right-radius: 0px !important;
}

.header .header-content:after {
    height: 1px;
    background-color: #332d28;
    left: 20px;
    width: 96.5%;
}

.content-body {
    padding-right: 0px;
}

.content-body .container-fluid,
.content-body .container-sm,
.content-body .container-md,
.content-body .container-lg,
.content-body .container-xl,
.content-body .container-xxl {
    padding-top: 1rem !important;
}

.custom-badge {
    width: 90px;
    display: inline-block;
    text-align: center;
}

.custom-approval-button {
    width: 80px;
    display: inline-block;
    text-align: center;
}

.all-approval-button {
    width: 100px;
    height: 35px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.all-approval-button-view {
    display: flex;
}

/*******************hamburger************/
.nav-control {
    display: none;
}

.header .header-content {
    padding-left: 35px;
}

/***************side-menu**************/
.nav-header .brand-logo {
    padding: 0px;
}

.brand-logo img {
    width: 100%;
}

.dlabnav .metismenu>li {
    padding: 0px !important;
}

.dlabnav .metismenu>li>a {
    border-radius: 0px !important;
}

.dlabnav .metismenu>li.mm-active>a {
    background-color: #514b46;
    box-shadow: none;
}

/*****************start-nav-header******************/
.nav-head-notify-icon-view {
    width: 110px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 50px;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 10px;
}

.nav-head-notify-icon-view button {
    border: none;
    background-color: transparent;
}

.nav-head-notify-icon-view button i {
    font-size: 20px;
    color: #ff9a55;
}

.nav-head-power-btn-view {
    padding-left: 10px;
}

.nav-head-power-btn-view button {
    border: none;
    background-color: transparent;
}

.nav-head-power-btn-view button i {
    font-size: 25px !important;
    color: #ff9a55;
}

.header-right .search-area {
    width: 11.625rem;
}

.search-area input {
    text-align: right;
}

.header-right .search-area .form-control {
    padding-right: 0px;
    border-radius: 50px;
}

.header-right .search-area .input-group-text {
    padding: 0px;
    border-radius: 50px;
}

.header-right .search-area .input-group-text i {
    color: #000000;
}

.add-invitation {
    margin-left: 10px;
}

.add-invitation a {
    background-color: #ff9a55;
    color: #ffffff;
    border-radius: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    line-height: 0px;
    font-size: 13px;
    font-weight: 500;
}

.add-invitation a i {
    font-size: 26px;
    padding-left: 5px;
}

.dlabnav .metismenu ul a:hover,
.dlabnav .metismenu ul a:focus,
.dlabnav .metismenu ul a.mm-active {
    color: #ff9a55;
}

/******************Custom-nav-scrollbar******************/
.nav-profile,
.mm-wrapper,
.metismenu {
    direction: ltr
}

.custom-nav-scrollbar {
    height: 100%;
    overflow-y: overlay;
    direction: rtl;
}

/* Designing for scroll-bar */
.custom-nav-scrollbar::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.custom-nav-scrollbar ::-webkit-scrollbar-track {
    background: gainsboro;
    border-radius: 5px;
}

/* Handle */
.custom-nav-scrollbar::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 5px;
}

/* Handle on hover */
.custom-nav-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #ede7e2;
}

/******************Account******************/
.title-body-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.title-body {
    width: 100%;
    /* margin-bottom: 20px; */
}

.title-body h3 {
    margin: 0px;
    color: #332d28;
    font-size: 24px;
}

.title-body p {
    margin: 0px;
    color: #332d28;
}

.title-filter-container {
    display: flex;
    align-self: center;
}

.search-filter {
    display: flex;
    background-color: #ffffff;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    padding: 0px 10px;
    border-radius: 6px;
}

.search-filter input {
    height: 40px;
    border: none;
    text-align: right;
    padding: 0px 10px;
    font-size: 14px;
    width: 140px;
}

.sort-filter {
    margin-right: 10px;
    display: flex;
}

.sort-filter input {
    height: 40px;
    border: none;
    padding: 0px 10px;
    font-size: 14px;
    border-radius: 6px;
    width: 160px;
}

.sort-filter select {
    height: 40px;
    border: none;
    padding: 0px 10px;
    font-size: 14px;
    border-radius: 6px;
    width: 160px;
}

.sort-filter button {
    width: 40px;
    height: 40px;
    background-color: #97928f;
    border: none;
    border-radius: 6px;
    margin-left: 10px;
}

.sort-filter button i.fa {
    color: #ffffff;
    font-size: 15px;
}

.search-filter i.fa {
    font-size: 18px;
    color: #000000;
}

.button-filter {
    display: flex;
    align-items: center;
}

.clock-time {
    width: 140px;
    color: #000000;
    font-size: 24px;
    font-weight: 500;
    padding-right: 15px;
}

.add-user-btn {
    width: 40px;
    height: 40px;
    background-color: #ff9a55;
    border: none;
    border-radius: 6px;
    margin-right: 10px;
}

.add-user-btn:disabled {
    background-color: #9d9d9d;
}

.time-sheet-filter-btn {
    width: 150px;
    /* padding: 0px 20px; */
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: #7e7c7a;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
}

.generate-report-btn {
    width: auto;
    height: 40px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
    background-color: #332d28;
}

.reset_filter-btn {
    width: 100px;
    height: 45px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
    background-color: #332d28;
}

.generate-report-btn:disabled {
    background-color: #7e7c7a;
}

.sendback_btn {
    background-color: #ff9a55;
    border-color: #ff9a55;
}

.card-filter-select {
    width: 200px;
}

.pending_badg {
    display: flex !important;
}

.pending_badg span {
    width: 18px;
    height: 18px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    color: #f0a901;
    font-size: 10px;
    margin-left: 4px;
    border: 1px solid #f0a901;
}

.main_pending_badg {
    display: flex !important;
}

.pending_badg_span {
    width: 18px;
    height: 18px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    color: #f0a901;
    font-size: 10px;
    margin-left: 4px;
    border: 1px solid #f0a901;
}

.card-filter-select .select-control {
    height: 40px !important;
    border-radius: 4px;
}

.card-filter-select .select-drop-down span {
    top: 12px !important;
    right: 15px !important;
}

.single-filter-select {
    width: 260px;
}

.time-sheet-filter-date-view {
    position: relative;
    width: 150px;
    margin-top: -19px;
    margin-right: 10px;
}

.time-sheet-filter-date-btn {
    width: 100%;
    padding: 0px 0px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: #7e7c7a;
    border: none;
    border-radius: 4px;
}

.timesheet-btn {
    background-color: #ff9a55;
    border: none;
    border-radius: 6px;
    margin-right: 10px;
    padding: 10px 10px;
    width: 120px;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
}

.capture-time-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-time-capture-container {
    flex: 1;
}

.add-capture-form {
    display: flex;
    align-items: center;
}

.time-capture-play-btn {
    padding: 10px;
    background-color: transparent;
    border: none;
}

.time-capture-play-btn i.fa {
    font-size: 28px;
    color: #787776;
}

.timetrack-form {
    width: 200px;
    margin-right: 5px;
}

.total-time-record {
    display: flex;
    padding-bottom: 20px;
    justify-content: space-between;
}

.total-time-record span {
    font-size: 18px;
}

.total-time-record h3 {
    font-size: 20px;
    margin: 0px;
}

/* .timetrack-form input {
    margin-left: 20px;
} */
.time-capture-filter-container {
    display: flex;
    align-items: center;
}

.time-capture-filter-btn {
    display: flex;
    align-items: center;
}

.time-capture-filter-btn .select-drop-down {
    width: 150px;
    margin-right: 10px;
}

.time-capture-filter-btn button {
    padding: 0px 20px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background-color: #7e7c7a;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
}

.time-capture-reset-btn {
    background-color: #787776 !important;
}

.time-capture-reset-btn:disabled {
    background-color: #9d9d9d !important;
}

.capture-check-input:checked {
    background-color: #787776 !important;
    border-color: #787776 !important;
}

.activeRow {
    background-color: #adadad;
}

.date-control-view {
    position: relative;
    width: 240px;
}

.date-control-view .react-datepicker__input-container input {
    width: 100%;
    padding: 0px 10px;
    height: 45px;
    font-weight: 400;
    background: #fff;
    color: #6e6e6e;
    line-height: 2.4;
    font-size: 13px;
    border-radius: 0.625rem;
    border: 1px solid #DBDBDB;
    appearance: none;
}

.filter-date-control {
    margin-right: 10px;
}

.filter-date-control .react-datepicker__input-container input {
    height: 35px;
    border-radius: 6px;
}

.filter-date-control .picker-btn {
    top: 10px;
}

.filter-date-control .picker-btn i {
    font-size: 14px !important;
}

.react-datepicker-popper {
    z-index: 2;
}

.picker-btn {
    background-color: transparent !important;
    position: absolute;
    top: 14px;
    right: 10px;
    border: none;
}

.picker-btn i {
    color: #6e6e6e;
    font-size: 16px;
}

.set-capture-time {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.set-capture-time button {
    background-color: #ff9a55;
    border: none;
    border-radius: 6px;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
}

/* .start-play{
    background-color: #787776 !important;
} */

.set-capture-time button:disabled {
    background-color: #787776 !important;
}

.set-capture-time button i {
    font-size: 30px;
}

.set-capture-time span {
    color: #000000;
    font-size: 24px;
    font-weight: 500;
}

.active-time {
    color: #cc5547;
}

.add-user-btn i.fa {
    color: #ffffff;
    font-size: 20px;
}

.add-filter-up-btn {
    width: 40px;
    height: 40px;
    background-color: #332d28;
    border: none;
    border-radius: 6px;
}

.add-filter-up-btn i.fa {
    color: #ffffff;
    font-size: 18px;
}

.add-btn-group {
    display: flex;
}

.add-btn-group button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 6px;
    margin-left: 10px;
    font-size: 18px;
    color: #6c6c6c;
    font-weight: 600;
    background-color: #c9c9c9;
}

.add-btn-group button[disabled] {
    color: #c7c7c7;
    background-color: #ebebeb;
}

.user-content-view {
    width: 100%;
    border-bottom: 1px solid;
    padding-bottom: 30px;
    padding-top: 10px;
}

.user-card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 15px 0px;
}

.user-card-img img {
    width: 55px;
    height: 55px;
    border-radius: 10px;
}

.user-card-content {
    margin-left: 10px;
}

.user-card-content h4 {
    margin: 0px;
    font-size: 13px;
}

.user-card-content p {
    margin: 0px;
    font-size: 12px;
    line-height: 1.3;
}

.add-user-card {
    width: 45px;
    height: 45px;
    border-radius: 10px;
    background-color: #ff9a55;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-user-card i {
    font-size: 26px;
    color: #ffffff;
}

.add-user-content h2 {
    font-size: 16px;
    padding-left: 10px;
    text-transform: capitalize;
    margin: 0px;
    font-weight: 400;
}

.subscription-content-view {
    width: 100%;
    margin-top: 20px;
}

.organization-info-card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px 20px 150px 20px;
}

.organization-info-title-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #000000;
    padding-bottom: 2px;
}

.organization-info-title-view h3 {
    margin: 0px;
    font-size: 18px;
    font-weight: 500;
    text-transform: capitalize;
    color: #332d28;
    padding-bottom: 2px;
    margin-top: 10px;
}

.organization-info-title-view i {
    font-size: 16px;
}

.organization-info-form-view {
    width: 100%;
    margin-top: 15px;
    padding: 0px 8px;
}

.organization-info-form-group {
    margin-bottom: 6px;
}

.organization-info-form-group label {
    font-size: 10px;
}

.organization-info-form-group input:hover {
    background-color: #f2f2f2;
}

.organization-info-form-group input {
    background-color: #f2f2f2;
    border: none;
    height: 32px;
    font-size: 12px;
}

.organization-info-form-group textarea {
    background-color: #f2f2f2;
    border: none;
    /* height: 60px; */
    font-size: 12px;
}

.subscribtion-card {
    background-color: #514b46;
    border-radius: 10px;
    padding: 30px;
}

.subscribtion-card-title-view {
    border-bottom: 1px solid #ffffff;
}

.subscribtion-card-title-view h3 {
    margin: 0px;
    color: #ffffff;
    font-weight: 400;
    padding-bottom: 2px;
}

.subscribtion-content {
    margin-top: 25px;
}

.subscribtion-plane-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px 20px 20px 20px;
}

.subscribtion-plane-left {
    position: relative;
}

.subscribtion-plane-left h3 {
    margin: 0px;
    color: #332d28;
    font-size: 18px;
}

.subscribtion-plane-left p {
    margin: 0px;
    color: #332d28;
    font-size: 14px;
    font-weight: 400;
}

.subscribtion-plane-btn {
    border: none;
    padding: 4px 24px;
    background-color: #ff9a55;
    color: #f2f2f2;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    top: -43px;
    left: 0px;
}

.subscribtion-expired-btn {
    border: none;
    padding: 4px 15px;
    background-color: #ff9a55;
    color: #f2f2f2;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
}

.subscribtion-plane-right h3 {
    margin: 0px;
    color: #332d28;
    font-size: 20px;
}

.subscribtion-plane-dis p {
    margin: 0px;
    font-size: 13px;
    color: #b2b2b2;
    padding-top: 5px;
    padding-bottom: 10px;
}

.subscribtion-plane-btn-content {
    display: flex;
    flex-wrap: wrap;
}

.subscribtion-plane-btn-content button {
    border: none;
    background-color: #ffffff;
    width: 140px;
    height: 40px;
    font-size: 14px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 6px 12px 6px 0px;
}

.account-setting-content {
    margin-top: 10px;
    margin-bottom: 30px;
}

.account-setting-card {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 25px 30px 20px 30px;
}

.account-setting-card h4 {
    margin: 0px;
    font-size: 18px;
    color: #332d28;
}

.account-setting-option {
    margin-top: 5px;
}

.account-setting-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffe1cc;
    border-radius: 6px;
    margin: 10px 0px;
    height: 35px;
    padding: 0px 15px;
}

.account-setting-item p {
    margin: 0px;
    font-size: 11px;
}

.caps-text {
    text-transform: capitalize;
}

/******************start-login-section******************/
.login-section {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
}

.login-logo-header {
    width: 100%;
    padding: 20px 0px 0px 20px;
    display: flex;
    align-items: center;
}

.login-logo-header img {
    width: 40px;
}

.login-logo-header h2 {
    margin: 0px;
    color: #000000;
    font-weight: 900;
    font-size: 26px;
    padding-left: 4px;
}

.login-form-section {
    width: 350px;
}

.login-form-section h3 {
    color: #000000;
    text-align: center;
    padding-bottom: 20px;
    font-size: 24px;
    font-family: system-ui;
}

.view-form-group {
    border: 1.5px solid #b7b7b7;
    display: flex;
    align-items: center;
    border-radius: 6px;
    background-color: #FFFFFF;
}

.form-group-icon {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-group-icon i.fa {
    font-size: 20px;
}

.cus-form-control {
    width: 100%;
    border: none;
    height: 45px;
    padding-right: 5px;
    font-size: 14px;
    font-weight: 600;
    background: none;
    outline: none;
}

.submit-btn {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #000000;
    margin-top: 30px;
    margin-bottom: 20px;
}

.auth-back-btn {
    border-radius: 6px;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #332d28;
    background-color: transparent;
    border: 2px solid #332d28;
    margin-right: 30px;

}

.signup-view {
    padding-top: 0px;
    text-align: center;
    padding-bottom: 20px;
}

.signup-view a {
    font-weight: 500;
    text-decoration: underline;
}

.forgot-password-view {
    padding-top: 0px;
    text-align: center;
}

.forgot-password-view a {
    text-decoration: underline;
    font-weight: 500;
}

.checkbox-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.create-account-text {
    color: #000000;
    font-weight: 600;
}

.foegot-pass-text {
    color: #676a6c;
}

.login-footer {
    width: 100%;
    text-align: center;
    background-color: #bcbcbc;
}

.login-footer p {
    color: #676a6c;
    font-size: 14px;
    margin: 0px;
    padding: 10px;
    font-weight: 400;
}

.login-footer p a {
    color: #676a6c;
}


/******************register-section******************/
.resister-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
}

.register-section {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.radio-form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.register-form-section {
    width: 50%;
    margin: 50px 0px;
}

.subscription-maincontainer {
    width: 100%;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; */
    background-color: #ffffff;
}

.form-section {
    width: 350px;
    margin: auto;
}

.border-buttom-1 {
    width: 100%;
    height: 1px;
    background-color: #e5e6e7;
    margin: 30px 0px 30px 0px;
}

.register-form-section h3 {
    color: #000000;
    text-align: center;
    padding-bottom: 20px;
    font-size: 26px;
    font-family: system-ui;
}

.radio-form-label span {
    font-size: 15px;
    color: #4e4d4d;
}

.custom-form-control {
    height: 45px !important;
    border-radius: 6px;
    font-weight: 600;
}

.continue-btn {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #000000;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 15px;
}

.cap-img {
    width: 120px;
}

.cus-form-label {
    font-weight: 800;
}

.otp-form-section {
    width: 280px;
}

.otp-form-section h3 {
    font-size: 36px;
    text-align: center;
    color: #000000;
    font-weight: 800;
}

.otp-form-section p {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: #a39898;
    padding-bottom: 20px;
}

.otp-form-section h4 {
    font-size: 15px;
    text-align: center;
    color: #4c4c4c;
    padding-bottom: 10px;
}

.otp-form-group {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 30px;
}

.otp-form-control {
    width: 40px;
    height: 40px;
    border: 2px solid #e5e6e7;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    padding-left: 13px;
}

.otp-btn {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #383838;
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: 600;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.otp-btn span {
    margin-left: 5px;
}

.form-group-pws {
    position: relative;
}

.input-control-pws {
    padding-right: 40px !important;
}

.pws-icon-view {
    position: absolute;
    top: 8px;
    right: 15px;
}

.pws-icon-view i {
    font-size: 20px;
}

.otp-btn:disabled {
    opacity: 0.6;
}

/**********************Individual-signin-section**************************/
.form-control:focus {
    border-color: #008CBA;
}

.register-option-section {
    width: 100%;
    /* background-color: red; */
}

.individual-company-text-view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 40px;
    padding: 0px 10px;
}

.individual-company-text-view h3 {
    font-size: 56px;
    margin: 0px;
    color: #4a4545;
}

.individual-company-text-view button {
    width: 170px;
    background-color: #f2f2f2;
    height: 50px;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    color: #4a4545;
    font-weight: 600;
}

.individual-company-text-view button:focus {
    background-color: #4c4343 !important;
    color: #fff !important;
}

.active-color {
    background-color: #4c4343 !important;
    color: #fff !important;
}

.or-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0px;
}

.or-option .horizantal-line {
    width: 40%;
    height: 1px;
    background-color: #333;
}

.or-title h4 {
    font-size: 20px;
    color: #4a4545;
}

.individual-button-view {
    margin-top: 10px;
}

.individual-button-view a {
    background-color: #f2f2f2;
    width: 100%;
    border: none;
    margin: 25px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    font-size: 26px;
    color: #4a4545;
    font-weight: 600;
}

.login-text-title-view {
    padding: 0px 10px;
    margin-bottom: 40px;
}

.login-text-title-view h3 {
    font-size: 26px;
    text-align: center;
    color: #383838;
}

.login-form-container {
    margin: 20px 0px;
    /* text-align: center; */
}

.form-group-item {
    margin-bottom: 20px;
}

.form-group-item label {
    display: flex;
    font-size: 15px;
    font-weight: 600;
    color: #332d28;
    padding: 0px 0px 7px 0px;
}

.required-form {
    border-color: #ff0000 !important;
}

.required-form .css-13cymwt-control {
    border-color: #ff0000 !important;
}

.input-control {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    border: 1px solid #989898;
    font-size: 16px;
    padding: 0px 10px 0px 20px;
}

.select-control {
    height: 45px !important;
    border-radius: 6px;
    border: 1px solid #989898;
    font-size: 14px;
    padding: 0px 10px 0px 10px;
}

.form-control-focus {
    border-color: #008CBA !important;
}

.filter-control {
    height: 35px !important;
    border-radius: 6px;
    border: 1px solid #DBDBDB;
    font-size: 13px;
    padding: 0px 10px 0px 10px;
}

.time-capture-label {
    font-size: 15px;
    font-weight: 600;
}

.add-time-capture-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.time-capture-classification-title {
    margin-top: 15px;
}

.time-capture-classification-title h3 {
    margin: 0px;
    font-size: 17px;
}

.add-time-capture-item button {
    width: 20px;
    height: 20px;
    background-color: #ff9a55;
    border: none;
    border-radius: 50px;
    color: #ffffff;
    font-size: 14px;
}

.time-capture-billing {
    display: flex;
    align-items: center;
    padding-top: 10px;
    /* justify-content: flex-end; */
}

.textarea-control {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #989898;
    font-size: 16px;
    padding: 10px 10px 0px 20px;
}

.login-option-section {
    width: 100%;
}

.auth-btn-view {
    display: flex;
    justify-content: center;
}

.login-btn {
    background-color: #332d28;
    color: #ffffff;
    border-radius: 6px;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-btn span {
    margin-left: 5px;
}

.input-control-merge input {
    border-right-color: transparent;
}

.phone-number-container {
    display: flex;
    border-radius: 6px;
    border: 1px solid #989898;
    height: 45px;
    align-items: center;
    /* margin-bottom: 20px; */
    background-color: #ffffff;
    position: relative;
}

.tooltip-info {
    position: relative;
    width: 20px;
    height: 20px;
    border: 1px solid #6e6e6e;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6e6e6e;
    position: absolute;
    top: 11px;
    right: -30px;
    cursor: pointer;
    font-size: 9px;
}

.tooltip-info .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #332d28;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    /* top: -5px; */
    right: -210px;
}

.tooltip-info .tooltip-text::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #332d28 transparent transparent;
}

.tooltip-info:hover .tooltip-text {
    visibility: visible;
}

.country-code-control {
    width: 60px;
    border: none;
    height: 30px;
    font-size: 17px;
    border-right: 1px solid #989898;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-left: 10px;
    color: #6e6e6e;
}

.country-code-control:disabled,
.form-country-code-control:disabled {
    background-color: transparent;
}

.form-country-code-control {
    width: 70px;
    border: none;
    height: 30px;
    font-size: 14px;
    border-right: 1px solid #989898;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-left: 10px;
    color: #6e6e6e;
}

.phone-control {
    flex: 1;
    border: none;
    height: 30px;
    font-size: 16px;
    border-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    padding-left: 10px;
    color: #6e6e6e;
}

.input-control-text {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #989898;
    border-radius: 0.25rem;
    border-left-color: transparent;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.input-control-text i.fa {
    font-size: 20px;
}

.check-input-control {
    margin-bottom: 20px;
}

.check-input-control label {
    color: #2f2b2b;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    display: block;
}

.check-input-control label a {
    color: #ee7b22;
}

.social-media-section {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
}

.social-media-item {
    width: 160px;
    background-color: #332d28;
    display: flex;
    align-items: center;
    border-radius: 50px;
    padding: 5px;
    color: #ffff;
    font-size: 16px;
    border: none;
}

.social-media-img {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    margin-right: 5px;
}

.have-account-view {
    margin: 30px 0px 20px 30px;
}

.have-account-view p {
    color: #332d28;
    text-align: center;
}

.have-account-view p a {
    color: #ee7b22;
    font-size: 14px;
}

.subscription-container {
    position: relative;
    margin-bottom: 50px;
    padding: 50px 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subscription-title-container {
    text-align: center;
    padding-bottom: 20px;
}

.subscription-item-container {
    width: 80% !important;
}

.subscription-title-container h2 {
    font-size: 28px;
    color: #ee7b22;
}

.subscription-title-container p {
    font-size: 15px;
}

.purchash-section {
    width: 100%;
    border: 1px solid #3D4853;
    padding: 10px 15px;
    border-radius: 6px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.purchash-title-view {
    position: relative;
    text-align: center;
    border-bottom: 1px solid #90A4AE;
    padding-bottom: 10px;
}

.purchash-title-view h3 {
    font-size: 20px;
    color: #3D4853;
}

.purchash-title-view h4 {
    font-size: 15px;
    color: #383838;
    margin: 0px;
    font-weight: 500;
}

.purchash-title-view h6 {
    font-size: 14px;
    color: #3D4853;
    font-weight: 600;
    margin: 0px;
    position: relative;
    padding-top: 10px;
}

.purchash-title-view h6 span {
    font-size: 30px;
    font-weight: 600;
}

.purchash-title-view h5 {
    font-size: 15px;
    font-weight: 500;
    color: #90A4AE;
}

.purchash-title-view h5 .color-free {
    font-size: 16px;
}

.purchash-title-view p {
    color: #90A4AE;
    font-size: 13px;
    line-height: 1.4;
}

.plan-checkbox {
    /* position: absolute;
    top: 0px;
    right: 0px; */
    /* border: 1px solid red; */
    /* cursor: pointer; */
    display: flex;
    align-self: center;
    justify-content: center;
    padding-top: 10px;
}

.purchash-item-view {
    width: 100%;
}

.purchash-item-view button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    border-radius: 6px;
    height: 40px;
    padding: 0px 20px;
    margin: 15px 0px;
    border-width: 1px;
    border-color: #90A4AE;
}

.purchash-item-view span {
    font-size: 14px;
    font-weight: 500;
    color: #495057;
}

.purchash-btn-view {
    display: flex;
    align-items: center;
    justify-content: center;
}

.purchash-btn {
    width: 100px;
    height: 35px;
    background-color: #383838;
    border: none;
    color: #ffffff;
    border-radius: 50px;
    font-weight: 600;
    font-size: 13px;
    margin: 0px 10px;
}

.puch-dls-btn {
    width: 100px;
    height: 35px;
    background-color: #ffffff;
    border: 1px solid #383838;
    color: #383838;
    border-radius: 50px;
    font-weight: 600;
    font-size: 13px;
    margin: 0px 10px;
}

.compare-plan-btn {
    background-color: #383838;
    border: none;
    color: #ffffff;
    border-radius: 50px;
    font-weight: 600;
    font-size: 13px;
    margin: 0px 10px;
    padding: 10px 20px;
}

.compare-plan-btn:disabled {
    opacity: 0.6;
}

.purchash-btn:hover {
    border-color: transparent !important;
}

.purchash-btn-view p {
    font-size: 13px;
    padding: 30px 0px 40px 0px;
    text-align: center;
}

.active-btn {
    border: 1px solid #ee7b22 !important;
}

.active-btn span {
    color: #ee7b22;
}

.purchash-item-view button:hover {
    border: 1px solid #ee7b22 !important;
}

.set-profile-container {
    background-color: #eaeaea;
    border-radius: 20px;
    padding: 25px 25px;
    margin-bottom: 20px;
}

.set-profile-container h3 {
    font-size: 18px;
    color: #383838;
}

.set-profile-container p {
    font-size: 13px;
}

.set-profile-container h4 {
    padding-top: 10px;
    color: #383838;
    font-weight: 700;
    font-size: 18px;
    margin: 0px;
}

.set-profile-container h4 a {
    color: #383838;
}

.set-profile-btn-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* margin-top: 20px; */
    padding: 0px 20px;
}

.set-profile-btn-container a {
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #383838;
    border-radius: 50px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #383838;
    margin-top: 20px;
}

.set-profile-back-btn {
    background-color: transparent !important;
    color: #383838 !important;
}

.set-profile-title-view {
    margin-bottom: 20px;
}

.set-profile-input-control {
    margin-top: 5px;
    margin-bottom: 0px;

}

.set-profile-input-control label {
    font-size: 14px;
    font-weight: 600;
}

.set-profile-input-control select {
    border: none;
    margin-top: 5px;
}

.confirm-form-group-item label {
    font-size: 15px;
    font-weight: 700;
    color: #332d28;
    padding: 0px 0px 7px 0px;
}

.confirm-form-group-item input {
    background-color: #eaeaea;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 10px;
    font-size: 14px;
    padding-left: 20px;
}

.foot-label-text p {
    font-size: 14px;
    font-weight: 400;
    padding-top: 2px;
}

.setting-text-title-view {
    margin-top: 50px;
}

.setting-text-title-view h3 {
    font-size: 26px;
    text-align: center;
    color: #383838;
    margin: 0px;
}

.setting-text-title-view p {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.setting-check-view {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    border: 1px solid #989898;
    font-size: 16px;
    padding: 0px 10px 0px 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.setting-check-view p {
    margin: 0px;
    padding-left: 10px;
}

/* The container */
.setting-label {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.setting-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.check-mark {
    position: absolute;
    top: -8px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #636363;
    border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.setting-label:hover input~.check-mark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.setting-label input:checked~.check-mark {
    background-color: #636363;
}

/* Create the checkmark/indicator (hidden when not checked) */
.check-mark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.setting-label input:checked~.check-mark:after {
    display: block;
}

/* Style the checkmark/indicator */
.setting-label .check-mark:after {
    left: 10px;
    top: 5px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.edit-form-setting {
    position: relative;
}

.edit-icon-form-btn {
    text-decoration: none;
    position: absolute;
    bottom: 5px;
    right: 10px;
    padding: 5px 8px;
}

.edit-icon-form-btn i.fa {
    color: #989898;
    font-size: 20px;
}

.setting-btn {
    background-color: #332d28;
    color: #ffffff;
    border-radius: 50px;
    padding: 15px 50px;
    font-size: 14px;
    font-weight: 600;
    border: none;
}

.select-drop-down {
    position: relative;
}

.select-drop-down span {
    position: absolute;
    top: 15px;
    right: 22px;
}

.select-drop-down span i {
    font-size: 16px;
}

.filter-drop-down span {
    position: absolute;
    top: 10px;
    right: 10px;
}

.filter-drop-down span i {
    font-size: 12px;
}

.congratulation-section {
    width: 100%;
    border: 2px solid #989898;
    padding: 50px 50px;
    text-align: center;
    border-radius: 10px;
}

.autocomplete-dropdown {
    width: 100%;
    position: absolute;
    top: 45px;
    left: 0px;
    border-top: 1px solid rgb(223, 225, 229);
    max-height: 300px;
    overflow: auto;
    background-color: #f2f2f2;
    z-index: 9;
}

.autocomplete-dropdown li {
    display: flex;
    align-items: center;
    padding: 0px 5px 0px 15px;
    height: 35px;
    font-size: 14px;
    cursor: pointer;
    text-transform: capitalize;
}

.autocomplete-dropdown li:hover {
    background-color: #e9e8e8;
}

.congratulation-section h2 {
    font-size: 36px;
}

.congratulation-section h3 {
    font-size: 18px;
    padding-bottom: 20px;
    margin-top: 15px;
}

.congratulation-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.congratulation-btn-group button {
    background-color: #332d28;
    color: #ffffff;
    border-radius: 6px;
    padding: 13px 40px;
    font-size: 14px;
    font-weight: 600;
    border: none;
    margin: 0px 10px;
}

.add-subcription-plan {
    padding: 40px 0px;
    text-align: center;
}

.subcription-plan-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.subcription-plan-btn-group button {
    border-color: #00000063;
    width: 80px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
}

.subcription-plan-btn-group>.confirm-btn {
    background-color: #000000;
    color: #ffffff;
    margin-left: 10px;
}

.text-capitalize {
    text-transform: capitalize;
}

.subcription-plan-details {
    position: relative;
    padding: 0px 20px;
}

.subcription-plan-details-title {
    /* text-align: center; */
    border-bottom: 1px solid #90A4AE;
}

.subcription-plan-details-title button {
    position: absolute;
    top: 5px;
    right: 15px;
    border: none;
    background-color: transparent;
    font-size: 20px;
}

.subcription-plan-details-title h2 {
    padding-top: 20px;
    font-size: 20px;
    margin: 0px;
}

.subcription-plan-details-title h5 {
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
}

.subcription-plan-details-title h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
}

.subcription-plan-details-title h4 span {
    font-size: 36px;
}

.subcription-plan-details-title p {
    font-size: 13px;
}

.subcription-plan-details-title h6 {
    font-size: 14px;
    color: #90A4AE;
    font-weight: 400;
}

.compare-details {
    position: relative;
}

.compare-details button {
    position: absolute;
    top: 5px;
    right: 15px;
    border: none;
    background-color: transparent;
    font-size: 20px;
}

.subcription-plan-details-view {
    padding-bottom: 20px;
}

.subcription-plan-details-view ul {
    margin: 0px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.subcription-plan-details-view ul li {
    width: 50%;
    margin: 0px;
    padding: 3px 0px;
    /* color: #ff9a55; */
    font-size: 13px;

}

.subcription-plan-details-view ul li span {
    color: #332d28;
    font-size: 14px;
}

/******************dashboard*******************/
#wrapper {
    background-color: #ede7e2;
}

.navbar-custom {
    background-color: transparent;
    box-shadow: none;
}

.logo-box {
    width: 280px;
    background-color: #332d28;
    padding: 0px 20px;
}

.dash-head-logo {
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    border-bottom: 1.4px solid #fff;
}

.logo-box .logo:hover {
    text-decoration: none;
}

.dash-head-title p {
    color: #332d28;
    margin: 0px;
    padding-top: 20px;
    font-weight: 500;
    font-size: 22px;
    padding-left: 30px;
}

.left-side-menu {
    width: 280px;
    background-color: #332d28;
}

.content-page {
    margin-left: 280px;
}

.footer {
    left: 280px;
}

.border-top-menu {
    width: 100%;
    background-color: #858280;
    height: 1.6px;
}

.iagree-label a {
    text-decoration: underline;
}

.otp-reset-view p {
    color: #332d28;
    font-size: 13px;
}

.otp-reset-view p button {
    color: #ee7b22;
    border: none;
    background-color: transparent;
    font-weight: 700;
}

.otp-reset-view p button:disabled {
    opacity: 0.6;
}

.custom-checkbox {
    display: flex;
}

.custom-control-label {
    padding-left: 5px;
    color: #332d28;
    text-align: left;
}

.custom-control-label a {
    color: #ee7b22;
}

.form-check-input {
    border-color: #332d28;
}

.form-check-input:checked {
    background-color: #332d28;
    border-color: #332d28;
}

.form-check-input:focus {
    border-color: #332d28;
}

.read-form-check-input {
    border-color: #DBDBDB;
}

.read-form-check-input:checked {
    background-color: #DBDBDB;
    border-color: #DBDBDB;
}

.expired-subcription-plan {
    padding: 40px 0px;
    text-align: center;
}

.expired-subcription-plan p {
    font-size: 15px;
    margin-top: -5px;
}

.subscription-plan-btn {
    background-color: #332d28;
    padding: 10px 30px;
    border-radius: 6px;
    color: #ffffff;
    font-size: 14px;
    margin-top: 10px;
}

.cancel-subscription {
    position: absolute;
    top: 0px;
    right: 10px;
    padding: 25px;
    cursor: pointer;
}

.cancel-subscription i.fa {
    font-size: 36px;
}

/**************************Breadcrumb**************************/
.breadcrumb-container {
    padding: 10px 15px;
    margin-bottom: 20px;
}

.breadcrumb-container ul {
    margin: 0px;
    display: flex;
}

.breadcrumb-container ul li {
    list-style: none;
    font-size: 14px;
    padding-right: 10px;
}

/***************side-nav-bar-profile-view******************/
.nav-profile {
    margin-top: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
}

.nav-profile-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-profile-img-left img {
    width: 40px;
    height: 40px;
    border-radius: 6px;
}

.nav-profile-img-right {
    flex: 1;
    padding-left: 10px;
}

.nav-profile-img-right h5 {
    margin: 0px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
}

.nav-profile-img-right p {
    margin: 0px;
    font-size: 10px;
    color: #ffffff;
    font-weight: 100;
    line-height: 1;
}

.nav-profile-edit-icon-view {
    padding: 0px 5px;
}

.nav-profile-edit-icon-view i {
    font-size: 20px;
}

.nav-profile-menu {
    margin-top: 5px;
}

.nav-profile-menu ul {
    margin: 0px;
}

.nav-profile-menu ul li {
    list-style: none;
}

.nav-profile-menu ul li a {
    padding: 10px 0px;
    display: block;
    text-align: center;
    color: #ffffff;
    font-size: 14px;
}

.nav-profile-menu ul .pp-active {
    background-color: #514b46;
}

/*****************************switch-button*****************/
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 0px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #332d28;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #ee7b22;
}

input:focus+.slider {
    box-shadow: 0 0 1px #ee7b22;
}

input:checked+.slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 40px;
}

.slider.round:before {
    border-radius: 50%;
}

.edit-profile-view {
    width: 100%;
    margin-top: 10px;
}

.edit-profile-view a {
    background-color: #ff9a55;
    display: block;
    text-align: center;
    color: #fff;
    padding: 10px 0px;
    font-size: 15px;
}

/************************global-loading*****************/
.loading-view {
    width: 100%;
    height: 100vh;
    background-color: #00000063;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-icon-view {
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*****************************Role-management********************/
.add-role-button-view {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.role-form-btn {
    background-color: #332d28;
    border-color: #332d28;
    padding: 10px 40px;
    margin-left: 10px;
}

.role-form-btn:disabled {
    background-color: #9d9d9d;
    border-color: #9d9d9d;
}

.role-form-btn:hover,
.role-form-btn:focus {
    background-color: #332d28;
    border-color: #332d28;
    opacity: 0.6;
}

.view-role-container {
    padding: 0px 50px;
}

.activities-list {
    justify-content: space-between;
    align-items: center;
}

.activities-back-btn {
    background-color: transparent;
    border: none;
    font-size: 26px;
    color: #9b8e8e;
}

.clock-btn {
    background-color: transparent;
    border: none;
    font-size: 36px;
    color: #6c6c6c;
}

/*******************Modal-view******************/
.add-user-modal-form-view {
    padding: 30px 30px;
}

.modal-title-view {
    text-align: center;
    padding: 0px 0px;
    margin-bottom: 30px;
}

.modal-title-view h3 {
    font-size: 26px;
    margin: 0px;
}

.modal-title-view p {
    font-size: 14px;
}

.client-title-view {
    margin-top: 10px;
    margin-bottom: 20px;
}

.client-title-view h3 {
    font-size: 20px;
    margin: 0px;
}

.client-modal {
    position: relative;
    height: 600px;
    overflow: auto;
}

.modal-cross-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 25px;
}

.form-calendar {
    position: relative;
}

.form-calendar-btn {
    border: none;
    position: absolute;
    top: 8px;
    right: 15px;
    font-size: 20px;
    color: #b3b3b3;
}

.form-group-modal-custom label {
    font-size: 14px;
    font-weight: 400;
}

.form-group-modal-custom input,
.form-group-modal-custom textarea,
.form-group-modal-custom select {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    border-radius: 10px;
    font-size: 14px;
    height: 45px;
}

.form-group-textarea-custom textarea {
    height: auto;
}

.form-group-modal-custom input:hover,
.form-group-modal-custom input:focus,
.form-group-modal-custom input:disabled,
.form-group-modal-custom textarea:hover,
.form-group-modal-custom textarea:focus,
.form-group-modal-custom select:hover,
.form-group-modal-custom select:focus {
    background-color: #f2f2f2;
    border-color: #989898;
    border-radius: 10px;
}

.form-inline-radio {
    display: flex;
}

.form-group-select label {
    font-size: 14px;
    font-weight: 400;
}

/* .client-accordion {
    width: 100%;
}

.client-accordion .accordion-collapse {
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #ff9a55;
    margin-top: 10px;
}

.client-accordion .accordion-button {
    background-color: #ff9a55;
    border: #ff9a55;
}

.client-accordion .accordion-button.collapsed {
    background: rgba(255, 154, 85, 0.1);
    border-color: rgba(255, 154, 85, 0.1);
    color: #ff9a55;
}

.client-accordion .accordion-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.client-accordion .accordion-button .accordion-button-text {
    font-size: 16px;
}

.client-accordion .accordion-button .accordion-button-indicator i.fa {
    font-size: 18px;
}

.client-accordion .form-check {
    padding-left: 12px;
    margin-bottom: 15px;
}

.client-accordion .form-check .form-check-input {
    margin-left: 0px;
    margin-top: 6px;
}

.client-accordion .form-check .form-check-label {
    margin-right: 10px;
    font-size: 16px;
}

.client-accordion .accordion-button::after {
    display: none;
} */

.client-country input {
    font-size: 14px !important;
}

.client-country .css-13cymwt-control {
    background-color: #f2f2f2;
    border-color: #f2f2f2 !important;
}

.client-country .css-1jqq78o-placeholder {
    font-size: 14px !important;
    color: #6e6e6e;
}

.client-country .css-13cymwt-control:focus {
    background-color: #f2f2f2 !important;
}

.client-country .css-1dimb5e-singleValue {
    font-size: 14px !important;
    color: #777777;
}

.client-country .css-qbdosj-Input {
    margin: 0px;
    padding: 0px;
}

.client-country .css-19bb58m {
    margin: 0px !important;
    padding: 0px !important;
}

.client-country .css-1fdsijx-ValueContainer {
    padding: 0px !important;
    margin: 0px !important;
}

.client-country .css-1fdsijx-ValueContainer {
    padding: 0px 0px !important;
}

.task-multi-select .css-13cymwt-control {
    background-color: #f2f2f2 !important;
    border: none !important;
    font-size: 14px !important;
}

.task-multi-select .css-t3ipsp-control {
    background-color: #f2f2f2 !important;
}

.task-multi-select .css-1p3m7a8-multiValue {
    background-color: hsl(0deg 0% 87.4%);
}

.custom-multiselect button {
    background-color: #f2f2f2;
    border-color: #989898;
    color: #777777;
    font-weight: 400;
    font-size: 14px;
}

.custom-multiselect button:focus {
    background-color: #f2f2f2;
    border-color: #989898;
}

.custom-multiselect .actions-btn {
    display: none;
}

.form-view-info {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    border-radius: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.form-view-info h3 {
    font-size: 14px;
    color: #6e6e6e;
    margin: 0px;
    padding-left: 10px;
}

.form-view-info h3 span {
    font-weight: 400;
    margin-left: 20px;
}

.classification-project-history {
    position: relative;
    cursor: pointer;
}

.classification-project-history span {
    position: absolute;
    top: 11px;
    right: 20px;
    font-size: 18px;
}

.classification-project-editbtn {
    background-color: #f0a802;
    color: #ffffff;
}

.classification-project-editbtn h3,
.classification-project-deletebtn h3 {
    color: #ffffff;
    padding-left: 30px;
}

.classification-project-deletebtn {
    background-color: #ff6a59;
    color: #ffffff;
}

.modal-button-group {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-cencel-btn {
    background-color: #ffffff;
    border-color: #332d28;
    color: #332d28;
    margin-right: 10px;
    padding: 10px 30px;
}

.role-menu-container {
    border: 1px solid #DBDBDB;
    border-radius: 0.625rem;
    padding: 10px 20px;
}

.sub-checkMark {
    margin-left: 25px;
}

.select-custom-form .css-1fdsijx-ValueContainer {
    padding: 0px 8px !important;
}

.select-custom-form .css-1jqq78o-placeholder,
.select-custom-form .css-qbdosj-Input {
    margin: 0px !important;
    padding: 0px !important;
}

.select-report-height {
    height: 450px;
}

.profile-select-custom-form input {
    height: auto;
}

.phone-number-form-group {
    display: flex;
    background-color: #f2f2f2;
    height: 45px;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.phone-number-form-group .phone-control {
    background-color: transparent;
    color: #6e6e6e;
    font-size: 14px;
}

/****************react-select************/

.css-13cymwt-control {
    height: 45px;
    border-radius: 6px !important;
    border: 1px solid #989898 !important;
    font-size: 16px;
    padding: 0px 10px 0px 10px;
}

.css-t3ipsp-control {
    height: 45px;
    border-radius: 6px !important;
    border: 1px solid #008CBA !important;
    font-size: 16px;
    padding: 0px 10px 0px 10px;
    box-shadow: none !important;
}

.css-1u9des2-indicatorSeparator {
    display: none;
}

#editCountry .css-13cymwt-control,
#editState .css-13cymwt-control,
#editCity .css-13cymwt-control {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    border-radius: 10px !important;
    padding: 0.375rem 0rem;
    font-size: 14px;
    border-color: transparent !important;
}

#editCountry .css-qbdosj-Input,
#editState .css-qbdosj-Input,
#editCity .css-qbdosj-Input {
    color: #6e6e6e !important;
}

#editCountry .css-t3ipsp-control,
#editState .css-t3ipsp-control,
#editCity .css-t3ipsp-control {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    border-radius: 10px !important;
    padding: 0.375rem 0rem;
    font-size: 14px;
    border-color: transparent !important;
    border: 1px solid #989898 !important;
    box-shadow: none !important;
    color: red !important;
}

#editCountry .css-1u9des2-indicatorSeparator,
#editState.css-1u9des2-indicatorSeparator,
#editCity .css-1u9des2-indicatorSeparator {
    display: none;
}

.css-tj5bde-Svg {
    color: #6e6e6e !important;
}

/********************profile*****************/
.profile-view-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.user-profile-thum {
    width: 100px;
    height: 100px;
    position: relative;
    text-align: center;
    border: 2px solid gray;
    border-radius: 50px;
    padding: 2px;
}

.user-profile-thum img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.custom-file-input {
    position: absolute;
    right: -5px;
    z-index: 9;
    bottom: -5px;
}

.custom-file-input label {
    color: white;
    background-color: #ff9a55;
    font-family: sans-serif;
    border-radius: 50px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/************************Time-capture****************/
.time-capture-section {
    width: 100%;
}

.time-capture-process-card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 30px 30px;
}

.time-capture-process-title {
    display: flex;
    justify-content: space-between;
}

.time-capture-process-title-lft {
    padding-right: 30px;
}

.time-capture-process-title-lft h3 {
    font-size: 20px;
    text-transform: capitalize;
    color: #332d28;
}

.time-capture-process-title-lft p {
    font-size: 12px;
    color: #000000;
}

.time-capture-process-title-rght button {
    border: none;
    padding: 8px 25px;
    border-radius: 20px;
    font-size: 16px;
    color: #514b46;
    font-weight: 500;
}

.time-capture-process-title-rght button i.fa {
    font-size: 20px;
}

.time-capture-process-content {
    width: 100%;
}

.time-process-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffe1cc;
    border-radius: 10px;
    padding: 0px 15px;
    margin-bottom: 10px;
    height: 50px;
}

.time-capture-process-card-dest h3 {
    font-size: 14px;
    margin: 0px;
    text-transform: uppercase;
    font-weight: 500;
}

.time-capture-process-card-dest p {
    font-size: 11px;
    margin: 0px;
    line-height: 10px;
}

.process-time-view p {
    margin: 0px;
    font-size: 13px;
    color: #514b46;
    font-weight: 500;
}

.add-task-Process-btn-view {
    padding-top: 10px;
}

.add-task-Process-btn-view button {
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    font-size: 16px;
    color: #514b46;
    font-weight: 500;
}

.add-task-Process-btn-view button i.fa {
    font-size: 20px;
}

.recent-activity-card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 30px 30px;
}

.recent-activity-card-title-view {
    padding-bottom: 10px;
}

.recent-activity-card-title-view h3 {
    font-size: 20px;
    text-transform: capitalize;
    color: #332d28;
}

.recent-activity-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    margin-top: 25px;
}

.recent-activity-time h4 {
    font-size: 15px;
    color: #bdbdbd;
    font-weight: 400;
}

.recent-activity-card-content-view {
    width: 100%;
}

.recent-activity-card-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffe1cc;
    border-radius: 10px;
    padding: 0px 5px 0px 15px;
    margin-bottom: 10px;
    height: 50px;
}

.recent-activity-card-item-lft h3 {
    margin: 0px;
    font-size: 14px;
}

.recent-activity-card-item-lft p {
    margin: 0px;
    line-height: 10px;
    font-size: 10px;
    text-transform: capitalize;
}

.recent-activity-card-item-rigt {
    display: flex;
    align-items: center;
}

.recent-activity-card-item-rigt h3 {
    margin: 0px;
    font-size: 15px;
}

.recent-activity-card-item-rigt .dropdown-toggle {
    padding: 5px 10px;
    background-color: transparent !important;
    border: none;
}

.recent-activity-card-item-rigt .dropdown-toggle i.fa {
    font-size: 18px;
    color: #000000;
}

/**********************Custom-Radio******************/

.custom-radio-form-check {
    width: 25px;
    height: 25px;
    background-color: #ffffff;
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 50px;
    margin-bottom: 0px;
}

.custom-radio-form-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-radio-checkmark {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 19px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
}

.custom-radio-form-check:hover input~.custom-radio-checkmark {
    background-color: #ccc;
}

.custom-radio-form-check input:checked~.custom-radio-checkmark {
    background-color: #ffffff;
}

.custom-radio-form-check .custom-radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-radio-form-check input:checked~.custom-radio-checkmark:after {
    display: block;
}

.custom-radio-form-check .custom-radio-checkmark:after {
    top: 1px;
    left: 0px;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #514b46;
}

/***************************Classification**********************/
.classification-section {
    width: 100%;
    margin-top: 20px;
}

.classification-card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 30px 30px;
    height: 330px;
}

.classification-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.classification-title h2 {
    font-size: 20px;
    color: #332d28;
    font-weight: 500;
}

.classification-title button {
    padding: 8px 15px;
    color: #ffffff;
    background: #332d28;
    border: none;
    border-radius: 6px;
}

.classification-body {
    width: 100%;
}

.classification-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffe1cc;
    border-radius: 10px;
    padding: 0px 15px;
    margin-bottom: 10px;
    height: 45px;
    cursor: pointer;
}

.classification-item h3 {
    margin: 0px;
    font-size: 15px;
    font-weight: 400;
    color: #525050;
    padding-right: 15px;
}

.classification-item-address {
    flex: 1;
}

.classification-item-address p {
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    color: #525050;
}

.classification-item i.fa {
    font-size: 20px;
    color: #525050;
}

.add-classification-card {
    background-color: #ffffff;
    border-radius: 15px;
    padding: 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.add-classification-icon {
    width: 55px;
    height: 55px;
    border-radius: 10px;
    background-color: #ff9a55;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-classification-icon i.fa {
    color: #ffffff;
    font-size: 36px;
}

.add-classification-content h2 {
    margin: 0px;
    font-size: 20px;
    color: #332d28;
    font-weight: 400;
    padding-left: 15px;
}

/********************client-tabview********************/
.client-tab-container .nav {
    align-items: center;
    justify-content: center;
}

.client-tab-container .nav-tabs {
    border-color: transparent;
}

.client-tab-container .nav-link {
    font-size: 18px;
}

.client-tab-container .nav-link:focus,
.client-tab-container .nav-link:hover,
.client-tab-container .nav-link.active {
    border-color: #ff9a55;
    color: #ff9a55;
}

/******************Add-emplooy-group***************/
.tine-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.tine-checkbox-group .form-check-label {
    margin-right: 10px;
}

.tine-checkbox-group .form-check .form-check-input {
    margin-left: -25px;
}

/******************Sweet-Alert***************/
.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
}

.success-checkmark .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;
}

.success-checkmark .check-icon::before {
    top: 3px;
    left: -2px;
    width: 30px;
    transform-origin: 100% 50%;
    border-radius: 100px 0 0 100px;
}

.success-checkmark .check-icon::after {
    top: 0;
    left: 30px;
    width: 60px;
    transform-origin: 0 50%;
    border-radius: 0 100px 100px 0;
    animation: rotate-circle 4.25s ease-in;
}

.success-checkmark .check-icon::before,
.success-checkmark .check-icon::after {
    content: '';
    height: 100px;
    position: absolute;
    background: #fff;
    transform: rotate(-45deg);
}

.success-checkmark .check-icon .icon-line {
    height: 5px;
    background-color: #4caf50;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 10;
}

.success-checkmark .check-icon .icon-line.line-tip {
    top: 46px;
    left: 14px;
    width: 25px;
    transform: rotate(45deg);
    animation: icon-line-tip 0.75s;
}

.success-checkmark .check-icon .icon-line.line-long {
    top: 38px;
    right: 8px;
    width: 47px;
    transform: rotate(-45deg);
    animation: icon-line-long 0.75s;
}

.success-checkmark .check-icon .icon-circle {
    top: -4px;
    left: -4px;
    z-index: 10;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    box-sizing: content-box;
    border: 4px solid rgba(76, 175, 80, .5);
}

.success-checkmark .check-icon .icon-fix {
    top: 8px;
    width: 5px;
    left: 26px;
    z-index: 1;
    height: 85px;
    position: absolute;
    transform: rotate(-45deg);
    background-color: #fff;
}

/*******************Custom-accodion************/

.account-container {
    width: 100%;
}

.account-item {
    margin-bottom: 20px;
}

.account-button {
    background-color: #fff5ee;
    height: 50px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    cursor: pointer;
    color: #ff9a55;
}

.account-button h3 {
    margin: 0px;
    font-size: 14px;
    color: #ff9a55;
}

.account-button span {
    font-size: 15px;
}

.account-collapse {
    padding: 0 10px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s;
}

.account-collapse.show {
    max-height: 1000px;
    padding: 20px;
}

.account-active {
    background-color: #ff9a55;
    color: #ffffff;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.account-active h3 {
    color: #ffffff;
}

.account-item-active {
    border: 2px solid #ff9a55;
    border-radius: 10px;
}

.acco-check {
    margin-bottom: 10px;
    padding-left: 14px;
}

.acco-check .form-check-input {
    margin-left: 5px;
}

.acco-check .form-check-label {
    margin-left: 0px;
}

.billing-check .form-check-label {
    font-weight: 100 !important;
}

.billing-check p {
    padding: 0px 5px;
}

.sorting-asc i.fa-long-arrow-up {
    color: #000000 !important;
}

.sorting-dec i.fa-long-arrow-down {
    color: #000000 !important;
}

/*********************No-data***************/

.nodata-found-view {
    width: 100%;
    text-align: center;
    padding: 100px 0px 50px 0px;
}

.nodata-found-view i.fa {
    font-size: 50px;
}

.nodata-found-view h2 {
    font-size: 24px;
    color: #a8a8a8;
    margin: 0px;
}

.nodata-found-view p {
    font-size: 14px;
}

.nodata-found-view button {
    padding: 10px 30px;
    border-radius: 6px;
    border: none;
    font-size: 14px;
    color: #dc3545;
}

/********************Custom-table*****************/
.cart-table {
    border-radius: 10px;
    margin: 0px;
    border: 1px solid #d7d7d7;
}

.cart-table-header {
    background-color: #d7d7d7;
    vertical-align: middle;
}

.cart-table-header th {
    /* padding: 5px 5px; */
    font-size: 14px !important;
}

.total-hrs {
    text-align: center;
    background-color: #d7d7d7 !important;
}

.overtime-hrs {
    text-align: center;
}

.total-time {
    background-color: #f3f1f1;
}

.total-time-pay {
    background-color: #d7d7d7;
}

.card-detail-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.card-detail-left label {
    font-size: 15px;
}

.card-detail-right label {
    font-size: 15px;
}

.time-capture-table td {
    padding: 0px 0.625rem;
}

.form-check-time:checked {
    background-color: #008CBA;
    border-color: #008CBA;
}

/********************System-default*****************/
.system-default-item {
    /* display: flex; */
    /* justify-content: space-between; */
}

.system-default {
    /* display: flex; */
}

.system-default h3 {
    font-size: 18px;
}

.system-default p {
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
}

.card-sheet-btn {
    padding: 2px;
    background-color: transparent;
    color: #777777 !important;
    border-color: transparent;
}

.card-sheet-btn:focus,
.card-sheet-btn:hover,
.card-sheet-btn .dropdown-toggle {
    background-color: transparent !important;
    border-color: transparent !important;
}

.card-sheet-btn i.fa {
    font-size: 18px;
}

.transaction-check-group {
    margin-top: 10px;
    width: 200px;
}

.transaction-check-group .form-check {
    margin: 00px 0px 10px 0px;
}

.system-title {
    margin-bottom: 20px;
}

.system-item-group {
    display: flex;
}

.system-title-item {
    /* width: 200px; */
    /* display: flex; */
}

.system-header-title {
    width: 200px;
    text-align: right;
    padding-right: 30px;
}

.system-submit-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.system-submit-container button {
    border: none;
    background-color: #332d28;
    color: #ffffff;
    padding: 10px 25px;
    font-size: 14px;
    border-radius: 6px;
}

/********************Pagination*****************/
.pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.page-result p {
    margin: 0px;
}

.alignResult {
    display: flex;
}

.alignResult p {
    align-self: center;
    margin-right: 20px;
}

.pagination-circle .page-item {
    margin-right: 0px;
    margin-left: 5px;
}

.pagination-circle .page-item .page-link {
    border-radius: 6px !important;
    color: #ff9a55 !important;
    border: none;
}

.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
    background-color: #ffeee2;
    color: #ff9a55;
    border: 1px solid #ff9a55;
}

.pagination .page-item.active .page-link {
    background-color: #ff9a55;
    border-color: #ff9a55;
    color: #fff !important;
}

.pagination .page-item .page-link:hover {
    background-color: #ff9a55;
    border-color: #ff9a55;
    color: #fff !important;
}

.pagination .page-item:first-child .page-link:hover,
.pagination .page-item:last-child .page-link:hover {
    background-color: #ff9a55;
}

.client-project-list ul {
    margin: 0px;
}

.client-project-list ul li {
    background-color: #f2f2f2;
    padding: 8px 10px;
    margin: 3px 0px;
}

.client-project-list h3 {
    margin: 0px;
    text-align: center;
    font-size: 18px;
}

.custom-btn-xs {
    padding: 2px 5px;
}

.manual-timesheet-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.manual-filter {
    flex: 1;
    /* margin-right: 5px; */
}

/********************Add-multiple-data**********************/
.add-multiple-section {
    display: flex;
    flex-direction: row;
    border: 1px solid #DBDBDB;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
}

/* .add-multiple-content{} */
.add-multiple-btn-content {
    margin-left: 10px;
}

.add-multiple-manualEntry {
    display: flex;
    justify-content: flex-end;
}

.review-modal {
    width: 100%;
}

.review-title-view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    padding: 10px 20px;
    background-color: #f7f5f4;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.review-content-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 400px;
    position: relative;
}

.review-chat-view {
    background-color: #f5efef;
    padding: 0px 10px;
    flex: 1;
    overflow: auto;
}

.review-cross-btn {
    background: none;
    border: none;
    font-size: 25px;
}

.review-chat-btn-view {
    height: 60px;
    background-color: #c3c3c3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.chat-input {
    flex: 1;
}

.chat-input-control {
    width: 100%;
    height: 45px;
    border-radius: 6px;
    border: none;
    padding: 10px;
}

.chat-btn-control {
    width: 60px;
    height: 45px;
    display: flex;
    justify-content: flex-end;
}

.chat-btn-control button {
    width: 45px;
    height: 45px;
    color: #FFFFFF;
    background-color: #ff9a55;
    border: none;
    border-radius: 6px;
    font-size: 26px;
}

.chat-btn-control button:disabled {
    opacity: 0.6;
}

.chat-bot-left {
    width: 100%;
    display: flex;
    margin: 5px 0px;
    flex-direction: column;
    align-items: flex-start;
}

.chat-bot-right {
    width: 100%;
    display: flex;
    margin: 5px 0px;
    flex-direction: column;
    align-items: flex-end;
}

.chat-bot-you {
    max-width: 80%;
    padding: 2px 10px;
    background-color: #fff;
    color: #000000;
    border-radius: 6px;

}

.chat-bot-you p {
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 18px;
}

.chat-bot-me {
    max-width: 80%;
    padding: 2px 10px;
    background-color: #ff9a55;
    color: #fff;
    border-radius: 6px;
}

.chat-bot-me p {
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 18px;
}

.chat-bot-name {
    color: #333;
    font-size: 13px;
    font-weight: 500;
}

.chat-bot-date {
    font-size: 10px;
}

.chat-loding-view {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-loding-view span {
    margin-bottom: 40px;
}

.sorting-btn {
    align-items: center;
    background-color: initial;
    border: none;
    color: #777;
    display: flex;
}

.sorting-icon-groups {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 5px;
    font-size: 12px;
}

.sorting-icon-groups i {
    line-height: 0px;
}

.sorting-asc i.fa-sort-asc {
    color: #000000;
}

.sorting-dec i.fa-sort-desc {
    color: #000000;
}

.timeSheet-project-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.timeSheet-project-title {
    font-size: 18px;
}

.assign-project-btm {
    border: none;
    padding: 10px;
    border-radius: 4px;
    background-color: #ff9a55;
    color: #ffffff;
    text-transform: capitalize;
}

.assign-project-btm:disabled {
    background-color: #d7d7d7;
}

.note-project-btm {
    width: 80px;
    height: 40px;
    border: none;
    padding: 10px;
    border-radius: 4px;
    background-color: #58bad7;
    color: #ffffff;
    text-transform: capitalize;
}

.note-project-btm:disabled {
    opacity: 0.6;
}

.new-node-btn {
    border: none;
    padding: 5px 12px;
    font-size: 10px;
    background: #58bad7;
    color: #ffffff;
    border-radius: 2px;
}

.report-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.report-header button {
    padding: 7px 20px;
    border: none;
    background: #332d28;
    color: #ffffff;
    border-radius: 4px;
}

.send-app-project-btm {
    width: 150px;
    height: 40px;
    border: none;
    padding: 10px;
    border-radius: 4px;
    color: #ffffff;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400;
}

.sent-project-btm {
    width: 100px;
    height: 40px;
    border: none;
    padding: 10px;
    border-radius: 4px;
    color: #ffffff;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 400;
}

.user-radio-supervisor {
    display: flex;
    align-items: center;
}

.timeEditable-control {
    width: 100%;
}

.report-tabview .nav-pills .nav-link {
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.report-tabview .nav-pills.light .nav-link.active,
.nav-pills.light .show>.nav-link {
    background-color: #332d28;
    color: #ffffff !important;
}

.supervisor-tabview .nav-pills.light .nav-link.active,
.nav-pills.light .show>.nav-link {
    background-color: #f0a901;
    color: #ffffff !important;
}

.progress-report-container {
    position: relative;
    height: 100px;
    padding-top: 10px;
    margin-bottom: 35px;
}

.progress-report {
    width: 100%;
    position: absolute;
    top: 5px;
}

.time-link {
    color: #f0a901;
    text-decoration: underline;
    border: none;
    background: none;
}

.disable-column {
    background-color: #f3f1f1 !important;
}

.tooltip-comment {
    position: relative;
    display: inline-block;
}

.tooltip-comment::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 120%;
    /* Position above the button */
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
}

.tooltip-comment:hover::after {
    opacity: 1;
    visibility: visible;
}

.progress-text p {
    margin: 0px;
    line-height: 20px;
}

.null-text {
    color: #ffffff;
}

.comment-modal-container {
    padding: 10px 10px;
}
.comment-modal-btn{
    text-align: right;
}
.comment-modal-btn button {
    background-color: #332d28;
    padding: 4px 10px;
    border-radius: 6px;
    color: #ffffff;
    font-size: 12px;
    margin-top: 10px;
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }

    5% {
        transform: rotate(-45deg);
    }

    12% {
        transform: rotate(-405deg);
    }

    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }

    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }

    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }

    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}